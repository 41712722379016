import React from "react"
import { Box, Heading, Button, Divider, Tag, Text } from "@chakra-ui/core"
import { Layout } from "../../components/layout/Layout"
import { graphql, Link } from "gatsby"
import rehypeReact from "rehype-react"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"
import { HeaderWithColor } from "../../components/headers/HeaderWithColor"
import { Seo } from "../../components/seo/Seo"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"
import { Heading1 } from "../../components/headings/Heading1"
import { HeaderLeft } from "../../components/headers/headerContainers/HeaderLeft"
import { HeaderRight } from "../../components/headers/headerContainers/HeaderRight"
import { Operators } from "../../components/operators/Operators"
import { MobilePlanCardsContainer } from "../../components/cards/productCads/mobilePlanCard/MobilePlanCardsContainer"
import { SloganText } from "../../components/texts/SloganText"
import { DescText } from "../../components/texts/DescText"
import { CompareContainer } from "../../components/compare/CompareContainer"
import { gql, useLazyQuery } from "@apollo/client"
import { CompareResults } from "../../components/compare/CompareResults"
import { RelatedLinks } from "../../components/navigation/related/RelatedLinks"
import { AdserviceCompare } from "../../components/compare/AdserviceCompare"

type Props = {
  data: any
  pageContext: any
}
// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const GET_PLANS = gql`
  query GetPlans($price: numeric, $internet: numeric, $unlimited: [Boolean!]) {
    MobilePlans(
      where: {
        monthlyPrice: { _lte: $price }
        internetSpeed: { _gte: $internet }
        unlimited: { _in: $unlimited }
      }
    ) {
      affiliate
      benefits
      id
      internetData
      internetSpeed
      internetType
      link
      monthlyPrice
      name
      openFee
      operator
      roamingData
      saleDuration
      salePrice
      slogan
      specialFilter
      unlimited
      usePrice
    }
  }
`

const Template: React.FC<Props> = ({ data, pageContext }) => {
  const {
    title,
    metaTitle,
    metaDesc,
    sidebar,
    slug,
    compareTitle,
    name,
    parent,
    desc,
    slogan,
  } = data.markdownRemark.frontmatter

  const [getPlans, result] = useLazyQuery(GET_PLANS)

  // Schema tiedot
  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })

  return (
    <Layout>
      <Seo title={metaTitle} desc={metaDesc} />
      <HeaderWithColor>
        <Box
          display="flex"
          alignItems="start"
          paddingTop="3rem"
          flexDir={["column", "column", "column", "column", "row"]}
        >
          <HeaderLeft>
            <Box justifyContent="center" paddingX={["1rem", "8rem"]}>
              <Box flex="1" marginX={["1rem", "2rem", "2rem", "2rem", "0rem"]}>
                <SloganText text={slogan} />
                <Heading1>{title}</Heading1>
                <DescText>{desc}</DescText>
              </Box>
              <Box marginTop="3rem" flex="1">
                <Link to="#kilpailuta">
                  <Button
                    color="white"
                    bg="action"
                    borderRadius="20px"
                    fontWeight="300"
                    width="300px"
                    height="50px"
                    fontSize="1.3rem"
                    border="none"
                    margin="1rem"
                    cursor="pointer"
                  >
                    Kilpailuta puhelinliittymät
                  </Button>
                </Link>
                <Link to="#kaikkipuhelinliittymat">
                  <Button
                    cursor="pointer"
                    color="action"
                    border="1px solid action"
                    borderRadius="20px"
                    fontWeight="300"
                    width="300px"
                    height="50px"
                    fontSize="1.3rem"
                    marginY="1rem"
                    margin="1rem"
                    outline="none"
                    borderStyle="solid"
                    borderColor="action"
                  >
                    Katso kaikki liittymät
                  </Button>
                </Link>
                <Operators />
              </Box>
            </Box>
          </HeaderLeft>
          {/* <HeaderRight>
            <a id="kilpailuta"></a>
            <CompareContainer handleCompare={getPlans} title={compareTitle} />
          </HeaderRight> */}
        </Box>
      </HeaderWithColor>
      <a id="ladataan"></a>
      <Box paddingX={["1rem", "8rem"]} paddingTop="4rem">
        <Heading textAlign="center">
          Aloita puhelinliittymien kilpailutus alapuolelta
        </Heading>
        <a id="kilpailuta"></a>
        <a id="kaikkipuhelinliittymat"></a>
        <AdserviceCompare />

        {/* <CompareResults
          loading={result.loading}
          called={result.called}
          plans={result.data && result.data.MobilePlans}
        />
        <Heading as="h4" size="md" textAlign="center" marginTop="6rem">
          Tutustu tästä kaikkiin tämän hetken puhelinliittymiin
        </Heading>
        <a id="kaikkipuhelinliittymat"></a>

        <MobilePlanCardsContainer
          slug={slug}
          plans={pageContext.mobilePlans}
          filters={true}
          compact={true}
        /> */}
      </Box>
      <RelatedLinks parent={parent} pageName={name} />
      <MainContainer>
        <Box flex="8">
          <Divider />
          <Box display="flex" justifyContent="center">
            <Link to="https://www.cvpohjat.fi/" target="_blank">
              <Box>
                <Tag marginRight="1rem" marginTop="0.3rem">
                  Mainos
                </Tag>

                <Text>
                  Valitse CV malli ja luo toimiva ansioluettelo minuuteissa{" "}
                  {">>>"}{" "}
                  <Text as="span" fontWeight="500">
                    cvpohjat.fi
                  </Text>
                </Text>
              </Box>
            </Link>
          </Box>
          <Divider />
          <TextContainer>
            {renderAst(data.markdownRemark.htmlAst)}
          </TextContainer>
        </Box>
        {sidebar && (
          <SidebarRight>
            <FixedSidebar>
              <Heading as="h3">Sivupalkki</Heading>
            </FixedSidebar>
          </SidebarRight>
        )}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        sidebar
        slug
        slogan
        name
        parent
        desc
        compareTitle
        metaTitle
        metaDesc
      }
    }
  }
`

export default Template
