import { Box } from '@chakra-ui/core';
import React from 'react'
import {Helmet} from "react-helmet";



export const AdserviceCompare = () => {



    const iframe = `<iframe id="as-comparison-iframe-467826966" style="border: none; width: 1px; min-width: 100%; overflow: hidden; " scrolling="yes" src="https://feed.aservice.tools/v2/public/publisher/comparisonfeed/iframe/mobilefeedinmalti" onload="iFrameResize({heightCalculationMethod: 'taggedElement', checkOrigin: false}, this);"></iframe>`

    return (
        <Box >
            <Helmet>
                <script type="text/javascript" src="https://static.ascontentcloud.com/comparisonfeed/resources/js/iframeResizer.min.js"></script>
            </Helmet>
        <Box dangerouslySetInnerHTML={{__html: iframe} } />
      </Box>


    )
}
